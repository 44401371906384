import React from "react";
import Layout from "../components/Layout";
import { PrivacyPolicySection } from "../sections";
import { BlueCurve1 } from "../components/Curves";

const PrivacyPolicyPage = () => {
  return (
    <Layout title="ChatIQ | Privacy Policy" darkFooter>
      <PrivacyPolicySection />
      <BlueCurve1 />
    </Layout>
  );
};

export default PrivacyPolicyPage;
